
const brand1 = "#943126";
const brand2 = "#515A5A";
const brand3 = "#21618C";

const neutral1 = "#fff";
const neutral2 = "#ccc";
const neutral3 = "#aaa";
const neutral4 = "#999";
const neutral5 = "#777";
const neutral6 = "#555";
const neutral7 = "#444";
const neutral8 = "#222";
const neutral9 = "#000";


export const theme = {
    fontSizeRems: {
        h1: 3.052,
        h2: 2.441,
        h3: 1.953,
        h4: 1.563,
        h5: 1.25,
        small: 0.8,  
    },
    color: {
        neutral1,
        neutral2,
        neutral3,
        neutral4,
        neutral5,
        neutral6,
        neutral7,
        neutral8,
        neutral9,
        brand1,
        brand2,
        brand3,
    },
    palette: {
        one: [neutral3, neutral7, neutral5],
        two: [brand1, brand2, brand3],
    }
}
