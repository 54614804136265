import React from 'react';
import { ThreeBG } from "../components/three/background/";
import { 
  MidGroundBox,
  BackgroundBox,
  Hero,
  HeroMeta,
  ThreeBox,
  GradientBox,
} from "./styles";

const App:React.FC = () => (
  <>
		<MidGroundBox>
			<Hero> 
        <HeroMeta>
          <h1>Josh Bosworth</h1>
          <p>Artist && Engineer</p>          
        </HeroMeta>
			</Hero>
		</MidGroundBox>
    <BackgroundBox>
      <ThreeBox>
        <ThreeBG />
      </ThreeBox>
      <GradientBox />      
    </BackgroundBox>
  </>
);

export default App;