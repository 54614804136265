import styled from "styled-components";
import { theme } from "../styles/theme";

export const ForeGroundBox = styled.div`
    display: flex;
    justify-content: center;
    align-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    pointer-events: none;
`
export const MidGroundBox = styled.div`
    display: flex;
    justify-content: center;
    align-content: flex-end;
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
`

export const Hero = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    min-height: 250px;
    width: 100vw;
    margin: auto 0;
    cursor: default;

    background-color: rgba(250, 250, 250, .6);
    box-shadow: 0.0rem 0.0rem 1.2rem ${theme.color.neutral3};
    color: ${theme.color.neutral1};
    text-shadow: 0rem 0rem 0.6rem ${theme.color.neutral7};

    transition: 
        background-color 250ms ease-in-out, 
        color 250ms ease-in-out, 
        text-shadow 250ms ease-out;

    &:hover{
        background-color: rgba(250, 250, 250, .8);     
        color: ${theme.color.neutral8};        
        text-shadow: 0rem 0rem .15rem ${theme.color.neutral9};             
    }

    h1 {
        letter-spacing: -.2rem;
        transition: letter-spacing 400ms ease-in-out;
    }

    &:hover h1{
        letter-spacing: .1rem;           
    }

`

export const HeroMeta = styled.div`
    text-align: center;
`

export const BackgroundBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
`

export const ThreeBox = styled.div`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
    pointer-events: auto;
`

export const GradientBox = styled.div`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;

    background: radial-gradient(circle at 100%, #333, #333 50%, #eee 75%, #333 75%);
`