import { css, createGlobalStyle } from "styled-components";
import { theme } from "./theme";

const globalStyles = css`
html {font-size: 100%;} /*16px*/

body {
  background: white;
  font-family: 'Amiko', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 1rem 0 0.5rem;
  font-family: 'Amiko', sans-serif;
  font-weight: 400;
  line-height: 1;
}

h1 {
  font-size: ${theme.fontSizeRems.h1}rem;
}

h2 {font-size: ${theme.fontSizeRems.h2}rem;}

h3 {font-size: ${theme.fontSizeRems.h3}rem;}

h4 {font-size: ${theme.fontSizeRems.h4}rem;}

h5 {font-size: ${theme.fontSizeRems.h5}rem;}

small, .text_small {font-size: ${theme.fontSizeRems.small}rem;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`