/* eslint-disable */
import * as THREE from 'three'
import React, { useRef, useState } from 'react'
import { Canvas, ReactThreeFiber, useFrame } from '@react-three/fiber';
import { ranIndex } from "../../../utils";
import { theme } from "../../../styles";

const theColor = ranIndex(theme.palette.one);

const Box = (props: JSX.IntrinsicElements['mesh']) => {
  // This reference will give us direct access to the THREE.Mesh object
  const ref = useRef<THREE.Mesh>(null!)
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Rotate mesh every frame, this is outside of React without overhead
  useFrame((state, delta) => {
    ref.current.rotation.z += (delta * 0.3);
    ref.current.rotation.x -= (delta * 0.3);
    ref.current.rotation.y += (delta * 0.3);
  }); 

  return (
    <mesh
      {...props}
      ref={ref}>
      <boxGeometry args={[.4, 20, 1]} />
      <meshStandardMaterial color={theColor} />
    </mesh>
  )
}

export const ThreeBG = () => (
    <Canvas>
      <ambientLight />
      <pointLight position={[5, 15, 10]} />
      <Box position={[0, 0, 0]} />
      <Box position={[-3, 0, 0]} />
      <Box position={[3, 0, 0]} />
    </Canvas>
)
